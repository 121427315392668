import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const HeroContainer = styled.section`
  height: 100vh;
  background-image: linear-gradient(241.65deg, rgba(2, 96, 113, 0.7) 31.14%, rgba(3, 206, 185, 0.7) 99.73%),
    url(${props => props.image});
  mix-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: normal;
  opacity: 1;
`

const HeroTextGroup = styled.div`
  position: absolute;
  width: 60%;
  top: 50%;
  left: 25%;
  transform: translate(-25%, -50%);
`

const HeroLabel = styled.h3`
  font-weight: 800;
  font-size: 4.8rem;
  color: #E9F744;
  margin-bottom: 64px;
`

const HeroTitle = styled.h2`
  font-weight: 800;
  line-height: 8.8rem;
  font-size: 8.8rem;
  color: white;
  margin-bottom:48px;
`

const HeroMoreDetails = styled.div`
  display: grid;
  width: 270px;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-items:left;
  
  a{
    color:white;
    font-weight: 800;
    font-size: 1.6rem;
  }

  &:before{
    content: '';
    display: inline-block;
    background-image: url(${props => props.iconMore});
    background-repeat:no-repeat;
    width:48px;
    height:48px;
  }
`

const HeroBoxGroup = styled.div`
  display:grid;
  grid-template-rows: 280px auto auto;
  position: absolute;
  width: 365px;
  height: 461px;
  right: 0px;
  bottom: 0px;
  padding: 48px;
  background: #E9F744;
`

const HeroBoxLocation = styled.div`
  background: transparent;
`

const HeroBoxTitle = styled.h4`
  font-weight: 800;
  font-size: 2.8rem;
  color: #026071;
`

const HeroBoxSubtitle = styled.h5`
  font-weight: 800;
  font-size: 2.8rem;
  color: rgba(2, 96, 113, 0.5);
`
const Hero = props => (
  <HeroContainer image={props.image}>
    <HeroTextGroup>
      <HeroLabel>{props.label}</HeroLabel>
      <HeroTitle>{props.title}</HeroTitle>
      <HeroMoreDetails iconMore={props.iconMore}>
        <Link to={props.url}>{props.textLink}</Link>
      </HeroMoreDetails>
    </HeroTextGroup>
    <HeroBoxGroup>
        <HeroBoxLocation>Maps</HeroBoxLocation>
        <HeroBoxTitle>{props.boxTitle}</HeroBoxTitle>
        <HeroBoxSubtitle>{props.boxSubtitle}</HeroBoxSubtitle>
    </HeroBoxGroup>
  </HeroContainer>
)
export default Hero