import React from 'react'
import styled from 'styled-components'

const ServicesContainer = styled.div`
  padding: 192px;
  background: #F5F5F5;
`

const ServicesTitle = styled.h3`
  font-weight:800;
  font-size: 6.4rem;
  text-align: center;
`

const ServiceSubtitle = styled.p`
  line-height: 3.2rem;
  font-size: 2.0rem;
  text-align: center;
  width:60%;
  margin:2.4rem auto;
`
const Services = props => (
  <ServicesContainer>
    <ServicesTitle>{props.title}</ServicesTitle>
    <ServiceSubtitle>{props.subtitle}</ServiceSubtitle>
    
  </ServicesContainer>
)

export default Services