import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import About from '../components/about';
import Services from '../components/services';

const IndexPage = () => (
  <Layout>
    <Hero 
      image={require('../images/estacion-belen.jpg')}
      label="Interventoría"
      title="Edificación de la Estación de Policia de Belén"
      url="/link"
      iconMore={require('../images/ic-more.svg')}
      textLink="Ver más sobre el proyecto"
      boxTitle="EDU - Estación de Policia"
      boxSubtitle="Medellín"
    />
    <About 
      title="Sobre HABOCIC"
      textMain="HABOCIC S.A.S., empresa Colombiana que se encuentra en el área de la ingeniería y Arquitectura, enfocada en los campos de la interventoría, la consultoría y la construcción en diferentes proyectos de edificaciones, obras civiles y urbanismos."
      textSecondary="A lo largo de los últimos veinte años HABOCIC ha tenido un gran crecimiento y ha logrado obtener reconocimiento a nivel nacional, en la ejecución e interventoría de proyectos de saneamiento básico."
    />
    <Services 
      title="Servicios"
      subtitle="Tenemos experiencia en diferentes ramas del sector de la ingeniería civil. Algunos de nuestros servicios son:"
    />
  </Layout>  
)

export default IndexPage
