import React from 'react'
import styled from 'styled-components'

const AboutContainer = styled.section`
  padding: 192px;
  display:grid;
  grid-template-columns: 40% 60%;
  grid-gap: 128px;
`

const AboutTitle = styled.h2`
  font-weight: 800;
  line-height: 9.6rem;
  font-size: 9.6rem;
`

const AboutTextGroup = styled.div`
  padding:0;
`

const AboutTextMain = styled.p`
  font-weight:800;
  line-height: 3.5rem;
  font-size: 2.0rem;
`

const AboutTextSecondary = styled.p`
  margin-top: 64px;
  line-height: 3.5rem;
  font-size: 2.0rem;
`

const About = props => (
  <AboutContainer>
    <AboutTitle>{props.title}</AboutTitle>
    <AboutTextGroup>
      <AboutTextMain>{props.textMain}</AboutTextMain>
      <AboutTextSecondary>{props.textSecondary}</AboutTextSecondary>
    </AboutTextGroup>
  </AboutContainer>
)

export default About